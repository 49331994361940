import Vue from 'vue';

export const notify = (type, title, text = '') => {
  Vue.notify({
    group: 'foo',
    type,
    title: title,
    text: text
  });
}

export const notifyStandardError = () => {
  notify('error', 'Nie udało się wykonać żądania', 'Spróbuj jeszcze raz')
}
